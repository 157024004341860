import { PageLayout } from '../../view/BaseLayout/PageLayout';
import React from 'react';

export const HelpPage = () => {
  return (
    <PageLayout title="PAGE_HELP_TITLE" description="PAGE_HELP_DESCRIPTION">
      <p>test</p>
    </PageLayout>
  );
};
